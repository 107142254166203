/** @jsx jsx */
// import React, { useState } from 'react';
import { Styled, jsx, Flex, Box, Grid } from "theme-ui"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

import { Container } from '../components/item'
import SEO from "../components/seo"

import Button from "../components/button"
import SafetySecurity from "../components/SafetySecurity"
import ImageArrowBlock from '../components/ImageArrowBlock'
import BgImage from '../components/BgImage'
import HorizontalCardCarousel from '../components/HorizontalCardCarousel'
import FeatureIcons from '../components/FeatureIcons'


import WhiteLogo from '../images/icons/nexprotek-white-background-icon.svg'

import HeroImage from '../images/placeholder.jpg'
import heroVideo from '../images/Nexprotek-1440-h264-intro.mp4'
import heroVideoWebM from '../images/Nexprotek-1080-h264-intro.webm'

export const query = graphql`
  query {
    welding: file(relativePath: { eq: "welding-blanket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fireBlanket: file(relativePath: { eq: "fire-blanket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interior: file(relativePath: { eq: "interior-shot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide1: file(relativePath: { eq: "fire-blanket-1.png"}) {
      childImageSharp {
        fluid(maxWidth: 410) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    weldingBlanketSlide: file(relativePath: { eq: "welding-blankets-slide.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fireExtinguisherSlide: file(relativePath: { eq: "fire-extinguisher-slide.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heatSoundSlide: file(relativePath: { eq: "heat-and-sound-slide.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
` 

const IndexPage = ({data}) => {

  const slideData = 
    [
      {
        "title": "Fire extinguisher",
        "text": "Fire extinguisher blankets are essential emergency devices at home and in the workplace. High-quality fire blankets in a home, garage, or business setting can prevent disaster in common emergencies like kitchen, fireplace, and electrical fires.",
        "link": "/use-cases/fire-extinguisher",
        "image": data.fireExtinguisherSlide.childImageSharp.fluid,
      },
      {
        "title": "Welding Blankets",
        "text": "Woven Silica Fiberglass welding blankets offer open spark and flame protection where needed. Can be placed on the ground or hung to provide a contained working area for welding, soldering, torch work or any other possible ignition source. They can be installed permanently or placed temporarily.",
        "link": "/use-cases/welding-blanket-industry-uses",
        "image": data.weldingBlanketSlide.childImageSharp.fluid,
      },
      {
        "title": "Heat and Sound Barrier",
        "text": "Everywhere from under vehicle hoods, vehicle floors, generator housings, and steam pipes to workshop walls, create a buffer for safety and comfort. Draped or installed, our barrier blankets are useful wherever you need thermal and sound control.",
        "link": "/use-cases/heat-and-sound-barrier",
        "image": data.heatSoundSlide.childImageSharp.fluid,
      },
    ];

  return(

    <Layout>
      <SEO 
        title="Fire Blankets for Security & Protection | Nexprotek Welding Blankets" 
        description="Nexprotek brings you the industry standard fire blanket, designed for protection in the home and the work place. Order yours and being to harness the protection a security blanket can offer."
      />

      <Box width={'100%'} sx={{ position: 'relative', overflow: 'hidden'}}>
        <video sx={{ display: 'block' }} width={'100%'} autoPlay muted loop poster={HeroImage}>
          <source src={heroVideo} type="video/mp4" />
          <source src={heroVideoWebM} type="video/webm"/>
        </video>
      </Box>
      
      <Flex as="section" bg={'black'} color={'white'}>
        <Container>
          <Flex py={7} sx={{
            color: 'white',
            bg: 'black',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}>
            <Styled.h3 sx={{ variant: 'text.caps'}}>How our fire blankets protect</Styled.h3>
            <Styled.p sx={{
              maxWidth: 820,
              variant: 'text.content'
            }}>Fire blankets are not as common as fire extinguishers, but they have many applications and use case scenarios. Our blankets are versatile, durable, and most importantly, effective when it comes to safety and security.</Styled.p>
          </Flex>
        </Container>
      </Flex>
  
      <Box bg='black' as="section">
        <Container sx={{ position: 'relative', paddingTop: [0,'40px'] }}>
          <HorizontalCardCarousel data={slideData}/>
        </Container>
      </Box>
  
      <Box as="section" my={7}>
        <FeatureIcons />
      </Box>
  
      <ImageArrowBlock fluid={data.welding.childImageSharp.fluid} title="Welding next to blanket">
        <Styled.h3 sx={{letterSpacing: '.1em'}}>Welding Blankets</Styled.h3>
        <p sx={{variant: 'text.body'}}>Our multipurpose welding blankets protect you, your tools, and your workspace. High-performance woven high silica fiberglass with silicone resists flames and flash temperatures up to 2192° F (1200° C), with long-term heat resistance between 662-842° F (350°-450° C). Available finished in standard sizes with grommets for easy use.</p>
        <Button 
          text="See Product"
          to="/products/welding-blankets"
          sx={{ 
          maxWidth: '200px',
          '@media screen and (max-width: 768px)': {
            marginTop: '20px',
            alignSelf: 'center',
          }
        }} />
      </ImageArrowBlock>
  
      <ImageArrowBlock reverse fluid={data.fireBlanket.childImageSharp.fluid} title="Welding next to blanket">
        <Styled.h3 sx={{letterSpacing: '.1em'}}>Fire Blankets</Styled.h3>
        <p sx={{variant: 'text.body'}}>A fire blanket is an essential emergency device at home and in the workplace, fighting flames without the use of harmful chemicals. Non-woven carbonized polyester wool resists up to 2192° F (1200° C), with long-term heat resistance between 662-842° F (350°-450° C). Our fire blankets are reusable and non-melting, and do not produce secondary pollution. Available in standard sizes.</p>
        <Button 
          text="See Product"
          to="/products/fire-blankets"
          sx={{ 
          maxWidth: '200px',
          '@media screen and (max-width: 768px)': {
            marginTop: '20px',
            alignSelf: 'center',
          }
        }} />
      </ImageArrowBlock>
  
      {/* <Container sx={{marginY: 8}}>
        <Carousel />
      </Container> */}
      
  
      <Box as="section" sx={{ 
        color: 'white',
        background: 'linear-gradient(174.8deg, #FDCB10 7.3%, #EF3E23 84.56%)',
        position: 'relative',
        zIndex: 1,
        }}>
        <Container>
          
          {/* <Image sx={{ height: '100%', width: 'auto', position: 'absolute', top: 0, left: 0, zIndex: '1' }} src={backgroundLogo} /> */}
  
          <Flex py={14} sx={{
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            position: 'relative',
            zIndex: '10',
          }}>
            <Styled.h2>We've got you covered.</Styled.h2>
            <p sx={{ variant: 'text.content', maxWidth: 820 }}>At Nexprotek, trial-by-fire is all in a day’s work. At home or at work in a high-heat industry, approach flames and extreme temperatures with performance-tested tools you can trust.</p>
          </Flex>
        </Container>
  
          <div sx={{ 
            height: '100%', 
            width: 'auto', 
            position: 'absolute', 
            top: '10px', 
            left: '-60px', 
            zIndex: 10 }}>
              <WhiteLogo />
          </div>
  
      </Box>
  
  
      <SafetySecurity />
  
      <BgImage fluid={data.interior.childImageSharp.fluid} title="Interior Shot" height={'auto'} >
        <div style={{height: '500px'}}></div>
      </BgImage>
  
      <div as="section" sx={{
        background: ['black', 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 25.46%)'],
        marginTop: ['-10px', '-400px', '-320px'],
        position: 'relative',
        zIndex: '10',
        textAlign: 'center',
        color: 'white',
        paddingTop: ['20px', '280px'],
        paddingBottom: '80px'
      }}>
        <Container px={4}>
          <Styled.h3 sx={{ variant: "text.caps", color: 'white' }}>Industry Resources</Styled.h3>
          <Grid width={[300, 350]} gap={['16px', '30px', '50px']} my={11} sx={{ color: 'white' }}>
            <Box sx={{textAlign: 'center'}}>
              <h4>Vertical Industries</h4>
              <p fontSize={0}>Nexprotek is ideal for suppliers to emergency services, commercial and heavy industries, and agriculture. Our products are available customized and in high volume.</p>
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <h4>Heavy Industry</h4>
              <p fontSize={0}>Manufacturing, construction, maritime, and mining industries all need fire-retardant material for daily hot work as well as for emergencies</p>
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <h4>Automotive Industry</h4>
              <p fontSize={0}>Our automotive materials ensure safety and comfort. They reduce road noise, create a heat barrier between engine and cabin, and prevent engine fires and overheating.</p>
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <h4>Marine Industry</h4>
              <p fontSize={0}>From shipyard construction to maintenance at sea, fire and insulation blankets are essential in marine industries. Cover and insulate electrical systems and vital equipment.</p>
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <h4>Agricultural Industry</h4>
              <p fontSize={0}>Thermal blankets save lives and livelihoods in farm and ranch industries. Protect harvesting vehicles, equipment, and flammable materials, and efficiently insulate outbuildings.</p>
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <h4>Commercial Industry</h4>
              <p fontSize={0}>Commercial settings - from factories to hotels - install fire blankets for emergencies. In settings where people work or gather, well-placed fire blankets can save lives.</p>
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <h4>Restaurant Industry</h4>
              <p fontSize={0}>Restaurants and commercial kitchens need fire blankets to control grease fires and other accidents. Safety regulations and laws often require them before a kitchen is approved.</p>
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <h4>Residential and Leisure</h4>
              <p fontSize={0}>Every kitchen, garage, and home workshop needs a fire-extinguisher blanket. Flame retardant blankets also increase safety and security on boats and vehicles.</p>
            </Box>
            <Box sx={{textAlign: 'center', gridColumn: ['auto', '1 / -1', 'auto'], width: ['100%', '50%', '100%'], margin: [0, '0 auto', 0]}}>
              <h4>Emergency Response</h4>
              <p fontSize={0}>Fire retardant material is not only useful in small fires. For fire departments and other emergency response teams, it provides personal protection in out-of-control blazes.</p>
            </Box>
          </Grid>
          <Button 
            to="/products" 
            text="See Products" 
            sx={{ width: '100%', maxWidth: '350px'}} />
        </Container>
      </div>
    
    </Layout>
  )
}

export default IndexPage
