/** @jsx jsx */

// import { graphql, StaticQuery } from 'gatsby'
import { Flex, Box, jsx } from 'theme-ui'
import Img from 'gatsby-image'

import { Container } from './item'

import orangeArrow from '../images/orange-thin-arrow.svg'

const ImageArrowBlock = ({reverse, fluid, imgTitle, children}) => {

  const boxMargin = () => {
    if ( reverse ) {
      return {
        marginRight: '-80px'
      }
    } else {
      return {
        marginLeft: '-80px'
      }
    }
  }

  return (


    <Box as="section">
      <Container>
          <Flex py={8} sx={{ 
            alignItems: 'center', 
            flexDirection:  reverse ? 'row-reverse' : '' ,    
            '@media screen and (max-width: 768px)': {
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }
          }}>
  
            <Box sx={{
              flex: '1 1 600px',
              '@media screen and (max-width: 768px)': {
                flex: '1',
                width: '100%'
              }
            }}>
              <Img fluid={fluid} title={imgTitle} sx={{
                maxWidth: '600px',
                '@media screen and (max-width: 768px)': {
                  maxWidth: 'none'
                }
              }}/>
            </Box>
  
            <Box sx={{
              flex: '1 1 620px', 
              ...boxMargin(),
              zIndex: 10,
              '@media screen and (max-width: 768px)': {
                marginLeft: 0,
                marginRight: 0,
                flex: '1',
              }
            }}>

            { reverse ?
              <Flex 
                pr={[10,13,13]} 
                pl={10} 
                py={4} 
                pb={[8,10,10]} 
                pt={[10, 0, 0]} 
                mt={[-8,-1,0]} 
                bg={'rgba(99, 100, 99, 0.8)'} 
                color={'white'} 
                sx={{ 
                flexDirection: 'column',
                justifyItems: 'space-around',
                position: 'relative',
                
                '::before': {
                position: 'absolute',
                right: '-50px',
                top: '50%',
                transform: 'translateY(-50%)',
                content: '""',
                width: '50px',
                height: '100px',
                borderBottomRightRadius: '100px',
                borderTopRightRadius: '100px',
                borderRight: 0,
                backgroundColor: 'rgba(99,100,99,0.8)',
                },
  
                '::after': {
                  position: 'absolute',
                  right: '-20px',
                  top: '50%',
                  transform: 'translateY(-50%) rotate(180deg)',
                  content: `url(${orangeArrow})`,
                  width: '40px',
                  height: 'auto',
                },
  
                '@media screen and (max-width: 768px)': {
  
                  '::before': {
                    position: 'absolute',
                    top: '-75px',
                    right: '50%',
                    transform: 'translateX(50%) rotate(90deg)',
                    content: '""',
                    width: '50px',
                    height: '100px',
                    borderBottomLeftRadius: '100px',
                    borderTopLeftRadius: '100px',
                    borderBottomRightRadius: '0',
                    borderTopRightRadius: '0',
                    borderRight: 0,
                    backgroundColor: 'rgba(99,100,99,0.8)',
                    },
        
                    '::after': {
                      position: 'absolute',
                      top: '-20px',
                      left: '50%',
                      transform: 'translateX(-50%) rotate(90deg)',
                      content: `url(${orangeArrow})`,
                      width: '40px',
                      height: 'auto',
                    },
                }
              }}>
  
                {children}
  
              </Flex>
              : 
              <Flex 
                pl={[10,13,13]} 
                pr={10} 
                py={4} 
                pb={[8,10,10]} 
                pt={[10, 0, 0]} 
                mt={[-8,-1,0]} 
                bg={'rgba(99, 100, 99, 0.8)'} 
                color={'white'} 
                sx={{ 
                flexDirection: 'column',
                justifyItems: 'space-around',
                position: 'relative',
                
                '::before': {
                position: 'absolute',
                left: '-50px',
                top: '50%',
                transform: 'translateY(-50%)',
                content: '""',
                width: '50px',
                height: '100px',
                borderBottomLeftRadius: '100px',
                borderTopLeftRadius: '100px',
                borderRight: 0,
                backgroundColor: 'rgba(99,100,99,0.8)',
                },
  
                '::after': {
                  position: 'absolute',
                  left: '-20px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  content: `url(${orangeArrow})`,
                  width: '40px',
                  height: 'auto',
                },
  
                '@media screen and (max-width: 768px)': {
  
                  '::before': {
                    position: 'absolute',
                    top: '-75px',
                    left: '50%',
                    transform: 'translateX(-50%) rotate(90deg)',
                    content: '""',
                    width: '50px',
                    height: '100px',
                    borderBottomLeftRadius: '100px',
                    borderTopLeftRadius: '100px',
                    borderRight: 0,
                    backgroundColor: 'rgba(99,100,99,0.8)',
                    },
        
                    '::after': {
                      position: 'absolute',
                      top: '-20px',
                      left: '50%',
                      transform: 'translateX(-50%) rotate(90deg)',
                      content: `url(${orangeArrow})`,
                      width: '40px',
                      height: 'auto',
                    },
                }
              }}>
  
                {children}
  
              </Flex>              
              }
            </Box>
          </Flex>
        </Container>
      </Box>
  )

}


export default ImageArrowBlock
