/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box, jsx, Styled } from 'theme-ui'
import Img from 'gatsby-image'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import FlameCut from '../images/flamecut.svg';
import Button from './button'

export default class extends React.Component {
  

  render() {
  const slideData =  this.props.data;

  const StyledDot = styled.li`
    cursor: pointer;
    color: white;
    background: none;
    padding: 5px 15px;
    margin: 10px;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;

    &:hover {
      
        background: linear-gradient(-2.2deg, #EF3E23 7.3%, #FDCB10 84.56%);
    }

    ${({ active }) => active && `
        color: white;
        background: linear-gradient(-2.2deg, #EF3E23 7.3%, #FDCB10 84.56%);
        transition: all 0.3s ease-in-out;

        &:hover {
          color: black;
        }
    `}

    @media screen and (max-width: 968px) {
      display: none;
    }
  `

  const StyledCarousel = styled(Carousel)`
    padding: 4rem 0;

    ul.react-multi-carousel-dot-list {
      position: absolute;
      top: 0px;
      bottom: auto;
    }
  `

  const ContentBox = styled(Box)`
    position: relative;
    z-index: 10;
    padding: 40px 30px;
    padding-left: 20px;

    ul {
      list-style: disc;

      li {
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 10px;
      }
    }
  `

  const ImageContainer = styled(Box)`
    width: 100%;
    height: 100%;
    z-index: 2;
  `

  const ItemDetails = styled(ContentBox)`
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.25);    
    background: rgba(88, 89, 91, 0.8) url(${FlameCut}) right center / auto 100% no-repeat;
  `

  const CustomDot = ({ onMove, index, onClick, active }) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    // const {width } = useWindowDimensions();

    return (
      <StyledDot
        active={active}
        onClick={() => onClick()}
      >
        {slideData[index].title}
      </StyledDot>
    );
  };
  
  return (

      <StyledCarousel
        bgColor={this.props.bgColor}
        additionalTransfrom={0}
        arrows={true}
        autoPlay={false}
        autoPlaySpeed={10000}
        centerMode={false}
        className=""
        containerClass="container-padding-bottom"
        // customButtonGroup={<CustomButtonGroup />}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        // renderButtonGroupOutside={true}
        renderDotsOutside={false}
        responsive={{
          screens: {
            breakpoint: {
              max: 6000,
              min: 0
            },
            items: 1,
          },
        }}
        showDots={true}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        customDot={<CustomDot />}
        sx={{ paddingTop: [0,'100px'] }} 
      >
       { 
         slideData.map((slide, i) => {
           return (
            <Flex key={i} sx={{ flexDirection: ['column', 'row'], alignItems: [ 'unset', 'center'], width: '80%', height: '100%', margin: '0 auto'}}>
              <Flex color='white' sx={{flex: '1 1 50%', flexDirection: 'column', paddingRight: '40px'}}>
                <Styled.h3 sx={{color: 'primary'}}>{slide.title}</Styled.h3>
                <Styled.p sx={{variant: 'text.body'}}>{slide.text}</Styled.p>
                { slide.link &&
                  <Button to={slide.link} text="Learn More" sx={{ maxWidth: '200px', marginTop: '20px'}} variant={'orangeOutline'} />
                }
              </Flex>
              <Box sx={{flex: '1 1 50%', position: 'relative', marginTop: [10, 0]}}>
                { slide.image &&
                  <ImageContainer>
                    <Img fluid={slide.image} />
                  </ImageContainer>
                }
                  
                  { slide.itemDetails && 
                    <ItemDetails sx={{marginTop: ['40px', 0]}} color='white'>
                      <ul>
                        {
                          slide.itemDetails.map((detail, index) => (
                            <li key={`${index} - ${slide.title}`}>
                              {detail}
                            </li>
                          ))
                        }
                      </ul>
                    </ItemDetails>
                  }
              </Box>
           </Flex>
           )
         })
       }

      </StyledCarousel>
    )
  }
}
