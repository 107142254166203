/** @jsx jsx */
import { graphql, StaticQuery } from 'gatsby'
import { Flex, Box, jsx, Styled } from 'theme-ui'

import Img from 'gatsby-image'

import { Container } from './item'

import SafetyIcon from '../images/icons/safety-icon.svg'
import TechnologyIcon from '../images/icons/technology-icon.svg'
import QualityIcon from '../images/icons/quality-icon.svg'

const SafetySecurity = () => (
  
  <StaticQuery
      query={graphql`
        {
          blanket: file(relativePath: {eq: "folded-blanket-fire.png"}) {
            childImageSharp {
              fluid(maxWidth: 450) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          background: file(relativePath: {eq: "dark-fire-background-compressor.jpg"}) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
    `}

    render={ (data) => (

      
      <Flex sx={{
        background: `url(${data.background.childImageSharp.fluid.src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
      }}>
        <Container as="section" px={4} py={6} sx={{
          color: 'white',
        }}>
          <Flex as="section" py={12} sx={{
            color: 'white',
            alignItems: 'center',
      
            '@media screen and (max-width: 1024px)': {
              flexDirection: 'column'
            }
          }}>
      
            <Box pt={'0'} sx={{
              flex: '2 4', 
              maxWidth: '450px',
              
              '@media screen and (max-width: 1024px)': {
                width: '80%'
              }
            }}>
              <Img px={4} width={'450px'} fluid={data.blanket.childImageSharp.fluid} alt="fire blanket" />
            </Box>
      
            <Box ml={[0, 0, '-200px']} mt={['60px', '60px', 0]} sx={{flex: '4 1', width: '100%'}}>
              <Flex mb={9}>
                <Box mt={'30px'} mr={[0,0,'20px']} sx={{ 
                  flex: '1 3', 
                  maxWidth: '150px',
                  '@media screen and (max-width: 1024px)': {
                    display: 'none',
                  }
                }}>
                  <SafetyIcon width={'100%'} />
                </Box>
      
                <Box sx={{ flex: '1 0'}}>
                  <Styled.h2>Safety & Security</Styled.h2>
                  <Styled.p pl={[0,0,'40px']} sx={{ variant: 'text.body'}}>Whether you’re a DIYer working on a project at home, a family that understands the value of protection equipment, or a rancher looking for the toughest textiles available, look to us. Our blankets are built to withstand and control any volatile situation.</Styled.p>
                </Box>
              </Flex>
      
              <Flex mb={9}>
                <Box mt={'25px'} mr={[0,0,'20px']} ml={[0,0,'160px']} sx={{ 
                  flex: '1 3', 
                  maxWidth: '50px',
                  '@media screen and (max-width: 1024px)': {
                    display: 'none',
                  }
                }}>
                  <QualityIcon width={'100%'} />
                </Box>
      
                <Box sx={{ flex: '1 0'}}>
                  <Styled.h2>Quality & Durability</Styled.h2>
                  <Styled.p pl={[0,0,'40px']} sx={{ variant: 'text.body'}}>When the heat is on and you need protection, quality matters. Our brand is built on a legacy of tried and true materials that withstand heat and facilitate your projects. Know your source, and know that your products work.</Styled.p>
                </Box>
              </Flex>
      
              <Flex mb={9}>
                <Box mt={'30px'} mr={[0,0,'20px']} ml={[0,0,'160px']} sx={{ 
                  flex: '1 3', 
                  maxWidth: '150px', 
                  '@media screen and (max-width: 1024px)': {
                    display: 'none',
                  }
                }}>
                  <TechnologyIcon width={'100%'} />
                </Box>
      
                <Box sx={{ flex: '1 0'}}>
                  <Styled.h2>Technology & Reliablity</Styled.h2>
                  <Styled.p pl={[0,0,'40px']} sx={{ variant: 'text.body'}}>We aren't pulling any punches - our manufacturers built a product that is designed for all heat-related trials. The research and effort we put into our products means we can stand behind their reliability. We won't fail you in your time of need.</Styled.p>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Flex>
     ) } 
  />
)

export default SafetySecurity
